<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-row
      class="mx-0"
    >
      <b-col
        sm="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Отчет по лидам
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer" />
              <span>Лидов</span>
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer" />
              <span>Потрачено, руб</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          ref="leadsRef"
          :key="componentKey"
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="chartOptions"
          :series="series"
        />
        <vue-apex-charts
          ref="spentsRef"
          :key="componentKey2"
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="chartOptions2"
          :series="series2"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
  BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    days: {
      type: Number,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const leadsRef = ref(null)
    const spentsRef = ref(null)

    const getPercentWidth = (w, n, x) => {
      const percentWidth = Math.round((x / (w / n)) * 100)
      return `${percentWidth}%`
    }

    const chartOptions = ref({
      tooltip: {
        shared: false,
        y: {
          formatter(val) {
            return `${val}`.replace('.', '').replace('-', '')
          },
        },
      },
      chart: {
        stacked: true,
        type: 'bar',
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -10,
        },
        yaxis: {
          lines: { show: false },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [$themeColors.primary],
      plotOptions: {
        bar: {
          columnWidth: '1',
          endingShape: 'rounded',
        },
        distributed: true,
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
          formatter(val) {
            // eslint-disable-next-line
            return val !== Infinity ? val * 1 : ''
          },
        },
      },
    })

    const chartOptions2 = ref({
      tooltip: {
        shared: false,
        y: {
          formatter(val) {
            return `${val}`.replace('.', '').replace('-', '')
          },
        },
      },
      chart: {
        stacked: true,
        type: 'bar',
        toolbar: { show: false },
      },
      grid: {
        padding: {
          top: -20,
          bottom: -10,
        },
        yaxis: {
          lines: { show: false },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [$themeColors.warning],
      plotOptions: {
        bar: {
          columnWidth: '1',
          endingShape: 'rounded',
        },
        distributed: true,
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: '#6E6B7B',
            fontSize: '0.86rem',
            fontFamily: 'Montserrat',
          },
        },
      },
    })

    const componentKey = ref(0)
    const componentKey2 = ref(1)
    const loading = ref(false)
    const series = ref([])
    const series2 = ref([])

    const fetchData = () => {
      loading.value = true
      store.dispatch('app-main/fetchSpentsLeads', {
        projectId: props.projectId,
        params: {
          filter: JSON.stringify({
            days: props.days,
            channels: props.channels,
          }),
        },
      })
        .then(res => {
          const { data } = res
          series.value = [
            {
              name: 'Лидов',
              // eslint-disable-next-line
              data: data.rows.map(item => item.leads || 0),
            },
          ]
          series2.value = [
            {
              name: 'Потрачено, руб',
              // eslint-disable-next-line
              data: data.rows.map(item => item.spent || 0),
            },
          ]
          chartOptions.value.plotOptions.bar.columnWidth = getPercentWidth(leadsRef.value.$el.clientWidth, data.rows.length, 10)
          chartOptions2.value.plotOptions.bar.columnWidth = getPercentWidth(spentsRef.value.$el.clientWidth, data.rows.length, 10)
          chartOptions.value.xaxis.categories = data.rows.map(item => moment(item.createdAt).format('DD.MM.YYYY'))
          chartOptions2.value.xaxis.categories = data.rows.map(item => moment(item.createdAt).format('DD.MM.YYYY'))
          loading.value = false
          componentKey.value += 1
          componentKey2.value += 1
        })
        .catch(() => {
          loading.value = false
        })
    }

    watch(() => props.projectId, () => {
      fetchData()
    })

    watch(() => props.days, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    watch(() => props.channels, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    fetchData()

    return {
      leadsRef,
      spentsRef,
      chartOptions,
      chartOptions2,
      componentKey,
      componentKey2,
      loading,
      series,
      series2,
    }
  },
}
</script>
