import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchProjects(context, payload) {
      return axios.get('project', payload)
    },
    async fetchSpents(context, payload) {
      return axios.get(`report/spents/${payload.projectId}`, { params: payload.params })
    },
    async fetchLeads(context, payload) {
      return axios.get(`report/leads/${payload.projectId}`, { params: payload.params })
    },
    async fetchStats(context, payload) {
      return axios.get(`report/stats/${payload.projectId}`, { params: payload.params })
    },
    async fetchSpentsLeads(context, payload) {
      return axios.get(`report/spents-leads/${payload.projectId}`, { params: payload.params })
    },
    async fetchKPI(context, payload) {
      return axios.get(`report/kpi/${payload.projectId}`, { params: payload.params })
    },
  },
}
