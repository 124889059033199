<template>
  <div>
    <b-card
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-row
            class="align-items-center"
          >
            <label class="pl-1">Аналитика по проекту</label>
            <b-col>
              <v-select
                v-model="project"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projects"
                :clearable="false"
                :filterable="false"
                input-id="project"
                :get-option-label="option => option.name"
                @input="selectProject"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-row
            class="align-items-center"
          >
            <label class="pl-1">канал</label>
            <b-col>
              <v-select
                v-model="selectedChannels"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="project ? project.channels : []"
                :clearable="false"
                :filterable="false"
                input-id="channel"
                multiple
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-row
            class="align-items-center"
          >
            <label class="pl-1">за</label>
            <b-col>
              <v-select
                v-model="selectedDays"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="days"
                :clearable="false"
                :filterable="false"
                input-id="day"
                :reduce="val => val.value"
                :get-option-label="option => option.label"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-row
      class="match-height"
    >
      <b-col
        sm="2"
      >
        <ecommerce-order-chart
          v-if="project"
          :project-id="project.id"
          :days="selectedDays"
          :channels="selectedChannels"
        />
      </b-col>
      <b-col
        sm="2"
      >
        <ecommerce-profit-chart
          v-if="project"
          :project-id="project.id"
          :days="selectedDays"
          :channels="selectedChannels"
        />
      </b-col>
      <b-col
        sm="8"
      >
        <card-statistics-group
          v-if="project"
          :project-id="project.id"
          :days="selectedDays"
          :channels="selectedChannels"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <analytics-support-tracker
          v-if="project"
          :project-id="project.id"
          :days="selectedDays"
          :channels="selectedChannels"
        />
      </b-col>
      <b-col sm="8">
        <ecommerce-revenue-report
          v-if="project"
          :project-id="project.id"
          :days="selectedDays"
          :channels="selectedChannels"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import mainStoreModule from './mainStoreModule'
import CardStatisticsGroup from './CardStatisticsGroup.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    CardStatisticsGroup,
    AnalyticsSupportTracker,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceRevenueReport,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-main'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, mainStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const days = [
      {
        label: 'прошедший день',
        value: 1,
      },
      {
        label: 'прошедшие 7 дней',
        value: 7,
      },
      {
        label: 'прошедшие 14 дней',
        value: 14,
      },
      {
        label: 'прошедшие 30 дней',
        value: 30,
      },
    ]
    const selectedDays = ref(14)

    const selectedChannels = ref([])

    const project = ref(null)
    const projects = ref([])

    const selectProject = val => {
      // eslint-disable-next-line
      selectedChannels.value = val.channels
    }

    const fetchProjects = () => {
      store.dispatch('app-main/fetchProjects')
        .then(response => {
          const { data } = response.data
          // eslint-disable-next-line
          project.value = data[0]
          // eslint-disable-next-line
          selectedChannels.value = data[0].channels
          projects.value = data
        })
        .catch(() => {
          project.value = null
          projects.value = []
        })
    }

    fetchProjects()

    return {
      projects,
      project,
      days,
      selectedDays,
      selectedChannels,
      selectProject,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
