<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-header>
      <b-card-title>{{ kpi.sum ? 'Выполнение KPI' : 'KPI не задан'}}</b-card-title>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="radialBar"
        height="270"
        :options="chartOptions"
        :series="[kpi.sum]"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BOverlay,
  },
  props: {
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    days: {
      type: Number,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = {
      plotOptions: {
        radialBar: {
          size: 150,
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '65%',
          },
          track: {
            background: '#fff',
            strokeWidth: '100%',
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: '#5e5873',
              fontSize: '1rem',
            },
            value: {
              offsetY: -28,
              color: '#5e5873',
              fontSize: '1.714rem',
            },
          },
        },
      },
      colors: [$themeColors.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [$themeColors.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        dashArray: 8,
      },
      labels: [''],
    }

    const loading = ref(false)
    const kpi = ref({
      sum: 0,
    })

    const fetchData = () => {
      kpi.value = { sum: 0 }
      loading.value = true
      store.dispatch('app-main/fetchKPI', {
        projectId: props.projectId,
        params: {
          filter: JSON.stringify({
            days: props.days,
            channels: props.channels,
          }),
        },
      })
        .then(res => {
          const { data } = res
          kpi.value = data
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    watch(() => props.projectId, () => {
      fetchData()
    })

    watch(() => props.days, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    watch(() => props.channels, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    fetchData()

    return {
      chartOptions,
      loading,
      kpi,
    }
  },
}
</script>
