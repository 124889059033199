<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-header>
      <b-card-title>Статистика</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row
        v-if="stats"
      >
        <b-col>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <i class="fas fa-binoculars" style="font-size: 24px"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="font-weight-bolder mb-0">
                {{ stats.coverage }}
              </h6>
              <b-card-text class="font-small-3 mb-0">
                Охват
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <i class="fas fa-mouse" style="font-size: 24px"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="font-weight-bolder mb-0">
                {{ stats.clicks }}
              </h6>
              <b-card-text class="font-small-3 mb-0">
                Кликов
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <i class="fas fa-percent" style="font-size: 24px"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="font-weight-bolder mb-0">
                {{ stats.ctr && stats.ctr.toFixed(2) }}%
              </h6>
              <b-card-text class="font-small-3 mb-0">
                CTR
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <i class="fas fa-ruble-sign" style="font-size: 24px"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="font-weight-bolder mb-0">
                {{ stats.cpc && stats.cpc.toFixed(2) }}
              </h6>
              <b-card-text class="font-small-3 mb-0">
                СРС, руб
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <i class="fas fa-filter" style="font-size: 24px"></i>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="font-weight-bolder mb-0">
                {{ stats.cv && stats.cv.toFixed(1) }}%
              </h6>
              <b-card-text class="font-small-3 mb-0">
                CR клик > лид
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow,
  BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  props: {
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    days: {
      type: Number,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)
    const stats = ref(null)

    const fetchData = () => {
      loading.value = true
      store.dispatch('app-main/fetchStats', {
        projectId: props.projectId,
        params: {
          filter: JSON.stringify({
            days: props.days,
            channels: props.channels,
          }),
        },
      })
        .then(res => {
          const { data } = res
          stats.value = data
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    watch(() => props.projectId, () => {
      fetchData()
    })

    watch(() => props.days, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    watch(() => props.channels, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    fetchData()

    return {
      loading,
      stats,
    }
  },
}
</script>
