<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <div>
      <h6>Лиды</h6>
      <h2 class="font-weight-bolder mb-1">
        {{ sum }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        :key="componentKey"
        height="70"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import moment from 'moment'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    BOverlay,
    VueApexCharts,
  },
  props: {
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    days: {
      type: Number,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = ref({
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        borderColor: $trackBgColor,
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          top: -30,
          bottom: -10,
        },
      },
      stroke: {
        width: 3,
      },
      colors: [$themeColors.info],
      markers: {
        size: 2,
        colors: $themeColors.info,
        strokeColors: $themeColors.info,
        strokeWidth: 2,
        strokeOpacity: 1,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: '#ffffff',
            strokeColor: $themeColors.info,
            size: 5,
          },
        ],
        shape: 'circle',
        radius: 2,
        hover: {
          size: 3,
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontSize: '0px',
          },
        },
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
        y: {
          formatter(val) {
            // eslint-disable-next-line
            return val && val * 1
          },
        },
      },
    })

    const componentKey = ref(0)

    const loading = ref(false)
    const sum = ref(null)
    const series = ref([{
      name: '',
      data: [],
    }])

    const fetchData = () => {
      loading.value = true
      store.dispatch('app-main/fetchLeads', {
        projectId: props.projectId,
        params: {
          filter: JSON.stringify({
            days: props.days,
            channels: props.channels,
          }),
        },
      })
        .then(res => {
          const { data } = res
          sum.value = data.sum
          series.value = [{
            name: '',
            data: data.rows.map(item => item.leads),
          }]
          chartOptions.value.xaxis.categories = data.rows.map(item => moment(item.createdAt).format('DD.MM.YYYY'))
          loading.value = false
          componentKey.value += 1
        })
        .catch(() => {
          loading.value = false
        })
    }

    watch(() => props.projectId, () => {
      fetchData()
    })

    watch(() => props.days, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    watch(() => props.channels, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    fetchData()

    return {
      componentKey,
      chartOptions,
      loading,
      sum,
      series,
    }
  },
}
</script>
