<template>
  <b-card
    body-class="pb-50"
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <div>
      <h6>Потрачено, руб</h6>
      <h2 class="font-weight-bolder mb-1">
        {{ sum && sum.toFixed(2) }}
      </h2>
      <!-- chart -->
      <vue-apex-charts
        ref="spentsRef"
        :key="componentKey"
        height="70"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import moment from 'moment'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    BOverlay,
    VueApexCharts,
  },
  props: {
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    days: {
      type: Number,
      required: true,
    },
    channels: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const spentsRef = ref(null)

    const getPercentWidth = (w, n, x) => {
      const percentWidth = Math.round((x / (w / n)) * 100)
      return `${percentWidth}%`
    }

    const chartOptions = ref({
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: -15,
          bottom: -15,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '1%',
          startingShape: 'rounded',
          colors: {
            backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
            backgroundBarRadius: 5,
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: [$themeColors.warning],

      xaxis: {
        labels: {
          show: false,
        },
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
    })

    const componentKey = ref(0)

    const loading = ref(false)
    const sum = ref(null)
    const series = ref([{
      name: '',
      data: [],
    }])

    const fetchData = () => {
      loading.value = true
      store.dispatch('app-main/fetchSpents', {
        projectId: props.projectId,
        params: {
          filter: JSON.stringify({
            days: props.days,
            channels: props.channels,
          }),
        },
      })
        .then(res => {
          const { data } = res
          sum.value = data.sum
          series.value = [{
            name: '',
            data: data.rows.map(item => item.spent),
          }]
          chartOptions.value.plotOptions.bar.columnWidth = getPercentWidth(spentsRef.value.$el.clientWidth, data.rows.length, 6)
          chartOptions.value.xaxis.categories = data.rows.map(item => moment(item.createdAt).format('DD.MM.YYYY'))
          loading.value = false
          componentKey.value += 1
        })
        .catch(() => {
          loading.value = false
        })
    }

    watch(() => props.projectId, () => {
      fetchData()
    })

    watch(() => props.days, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    watch(() => props.channels, () => {
      if (props.projectId) {
        fetchData()
      }
    })

    fetchData()

    return {
      spentsRef,
      componentKey,
      chartOptions,
      loading,
      sum,
      series,
    }
  },
}
</script>
